// @flow

import React from 'react'
import classNames from 'classnames'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import BackgroundOverlay from '../../CommonLanding/shared/BackgroundOverlay'

import messages from '../CaseStudyModern.lang'
import baseStyles from './TechSection.scss'

type Props = {
  title?: any,
  text?: any,
  techLogos?: Array<Object>,
  techLogosMore?: boolean,
  productLogos?: Array<Object>,
  productLogosMore?: boolean,
  modeSettings?: Object,
  ...StyleProps
}

const TechSection = ({
  modeSettings,
  title,
  text,
  techLogos,
  techLogosMore,
  productLogos,
  productLogosMore,
  styles
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, modeSettings && modeSettings.mode === 'dark' && styles.isDark)}>
      <BackgroundOverlay styles={styles.overlay} {...modeSettings} />
      <Container className={styles.container} fluid>
        {title && (
          <h2
            dangerouslySetInnerHTML={{ __html: formatMessage(title || messages.techTitle) }}
            className={styles.title}
          />
        )}
        {text && <div dangerouslySetInnerHTML={{ __html: formatMessage(text) }} className={styles.text} />}
        {techLogos && techLogos.length > 0 && (
          <div className={styles.logoList}>
            {techLogos.map((item, idx) => {
              return (
                <div className={styles.logoItem} key={idx}>
                  {item.img}
                </div>
              )
            })}
            {techLogosMore && <div className={styles.logoItem}>...</div>}
          </div>
        )}
        {productLogos && productLogos.length > 0 && (
          <>
            <h3 className={styles.subtitle}>{formatMessage(messages.ourProducts)}</h3>
            <div className={styles.logoList}>
              {productLogos.map((item, idx) => {
                return (
                  <Link to={item.url} key={idx}>
                    <div className={classNames(styles.logoItem, styles.productItem)}>{item.img}</div>
                  </Link>
                )
              })}
              {productLogosMore && <div className={styles.logoItem}>...</div>}
            </div>
          </>
        )}
      </Container>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TechSection)
