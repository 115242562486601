// @flow

import React, { useCallback } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import FloatingCTA from '../basic/FloatingCTA'
import MainTeaser from './MainTeaser'
import Section from '../CommonLanding/Section'
import TechSection from './TechSection'
import RelatedCS from './RelatedCS'

import baseStyles from './CaseStudyModern.scss'

type Props = {
  ...StyleProps,
  data: Array<Object>
}

const CaseStudyModern = ({ styles, data }: Props) => {
  const renderItem = useCallback(
    (item, idx) => {
      switch (item.type) {
        case 'MainTeaser':
          return (
            <MainTeaser
              title={item.title}
              logo={item.logo}
              logoBg={item.logoBg}
              modeSettings={item.modeSettings}
              key={idx}
            />
          )
        case 'Section':
          return (
            <Section
              sectionTitle={item.sectionTitle}
              sectionIcon={item.sectionIcon}
              sectionIconColour={item.sectionIconColour}
              title={item.title}
              text={item.text}
              list={item.list}
              buttonLabel={item.buttonLabel}
              buttonLink={item.buttonLink}
              gallery={item.gallery}
              quotes={item.quotes}
              imageRow={item.imageRow}
              galleryAlignment={item.galleryAlignment}
              modeSettings={item.modeSettings}
              key={idx}
            />
          )
        case 'TechSection':
          return (
            <TechSection
              title={item.title}
              text={item.text}
              techLogos={item.techLogos}
              techLogosMore={item.techLogosMore}
              productLogos={item.productLogos}
              productLogosMore={item.productLogosMore}
              modeSettings={item.modeSettings}
              key={idx}
            />
          )
        case 'RelatedCS':
          return <RelatedCS title={item.title} items={item.relatedCases} modeSettings={item.modeSettings} key={idx} />
        case 'FloatingCTA':
          return <FloatingCTA img={item.img} modeSettings={item.modeSettings} key={idx} />
        default:
          console.warn('There is no teaser type ' + item.type)
      }
    },
    [data]
  )

  return (
    <div className={styles.root}>
      <ParallaxProvider>{data.map(renderItem)}</ParallaxProvider>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseStudyModern)
