import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  mainTeaserTitle:
    'Maximizing B2B Potencial:<br/>Integration of Content Management, CRM, and Web<br />Services at Mitsubishi Factory Automation',
  theCompanySectionTitle: 'Mitsubishi Electric Factory<br />Automation',
  theCompanySectionText:
    'Mitsubishi Electric Factory Automation is a global leader in the field of industrial<br />automation. The technological support provided by freshcells systems engineering<br /> enables Mitsubishi Electric Factory Automation to achieve these goals, delivering valuable<br /> features to both users and content creators through the website and the headless CMS.',
  theChallengeTitle:
    'Developing a Comprehensive and<br /> Flexible HCMS Infrastructure for<br /> International Requirements',
  theChallengeText: `
      <p>As an internationally operating company with a complex product range, the Mitsubishi Electric Factory Automation web platform serves as a crucial point of contact for visitors from various markets and industries.</p>
      <p>Integrating a headless content management system (HCMS) for managing content across 21 countries, meeting user management requirements, and seamlessly integrating additional functions and systems presented a complex challenge. The overarching goal was to completely relaunch the existing legacy platform and migrate to a sustainable, scalable solution. The introduction of the HCMS enabled the management of all content and downloads, the import of external product information, and made it accessible to all website users through a custom-developed search function.</p>
      <p>Additionally, a customer relationship management (CRM) system was integrated, along with login systems and various web services. The focus was on optimizing lead generation while ensuring users were quickly guided to the content relevant to them through convenient features. An asset management system was also to be integrated.</p>`,
  technicalChallengeTitle: 'Technical and Structural Challenges',
  multilingualContentTitle: 'Multilingual Content Management',
  multilingualContentText:
    'Managing content in multiple languages and markets, as well as ensuring a well-developed content inheritance logic, were complex tasks.',
  customUserRolesTitle: 'Custom User Roles',
  customUserRolesText:
    'Adapting to different organizational structures and requirements placed high demands on user roles.',
  seamlessIntegrationTitle: 'Seamless Integration',
  seamlessIntegrationText:
    'Additional functions and integrations required special attention to data consistency, interoperability, and consistent performance.',
  localizationTitle: 'Localization and Globalization Issues',
  localizationText: 'Adjustments for different regions were necessary to ensure a consistent global user experience.',
  contentGovernanceTitle: 'Content Governance and Compliance',
  contentGovernanceText:
    'Managing content across multiple locations increased the requirements for regulations and consistency.',
  contentSearchTitle: 'Product, Download & Website Content Search',
  contentSearchText:
    'The custom-developed search function impresses with performance, flexibility, and controllability, specifically designed to meet very specific requirements.',
  performanceSectionTitle: 'Performance and Security Requirements',
  performanceOptiomizationTitle: 'Performance Optimization',
  performanceOptiomizationText:
    'Optimizing platform performance for a positive user experience and fast response times.',
  securityTitle: 'Security and Access Control',
  securityText: 'Robust security measures and access controls across different regions were necessary.',
  scalabilityTitle: 'Scalability',
  scalabilityText: 'Planning for future growth to handle increased data loads and user interactions.',
  userTrainingAndAdoptionTitle: 'User Training and Adoption',
  userTrainingAndAdoptionText: 'The introduction of the HCMS required extensive training and detailed user manuals.',
  interoperabilityTitle: 'Interoperability with External Systems',
  interoperabilityText:
    'Ensuring compatibility and smooth interactions between the HCMS, the website, and external systems.',
  sectionQuoteText1:
    'Overcoming these challenges required careful planning, innovative technologies, and close collaboration between the involved teams. The focus was on developing a dynamic and expandable software solution that not only meets current requirements but is also prepared for future developments.',
  implementationSectionTitle: 'The Implementation',
  implementationTitle: 'Scalable Platform with Modern Technology',
  implementationText:
    '<p>To address these challenges, a solution was developed based on cutting-edge technologies and systems engineering principles. A strategy based on API & microservice architecture for the web guided the software development, creating a scalable and global platform solution.</p><p>The headless content management system "ContentStack" was integrated to manage various content types and provide an intuitive content management experience for 21 countries. Customized functionalities were developed to meet specific customer requirements. Additional technologies such as Elastic Search, RabbitMQ, and NextJS were employed to ensure a powerful search experience, optimized load times, and a responsive user interface.</p><p>KeyCloak, as a single sign-on service, was implemented for user login management to enhance data security by adjusting access levels according to organizational structure and individual responsibilities. Kubernetes enabled the creation of a scalable solution for seamless deployment and management of the integrated systems. This is facilitated by collaboration with a Japanese hosting provider, creating a stable and scalable hosting environment. The CRM system integration allows Mitsubishi Electric Factory Automation to optimize lead generation processes.</p>',
  outcomeSectionTitle: 'The Outcome',
  outcomeTitle: 'Efficient, Dynamic Platform Enhances B2B Capabilities',
  outcomeText:
    'Mitsubishi Electric Factory Automation strengthens its operations on a future-proof and resilient platform, achieving strategic goals and maximizing B2B capabilities:',
  outcomeListTextOne:
    "Mitsubishi's direct control over all content allows centralized management, leading to more efficient and organized processes.",
  outcomeListTextTwo:
    'The transition from a static to a dynamic system ensures more flexible functionalities, responsiveness, and adaptability, which is particularly important for meeting changing demands and environments.',
  outcomeListTextThree:
    'The adaptation of rights and role concepts to the international corporate structure ensures a harmonized and standardized approach, improving collaboration, security, and consistency across the organization.',
  outcomeListTextFour:
    'Process optimization enables the client to achieve faster and more efficient results. The ability to find and filter search results across all media within milliseconds significantly improves the speed and effectiveness of information retrieval, providing the client with enhanced content.',
  outcomeListTextFive:
    'Additional systems such as CRM were integrated to enable process automation for lead generation.',
  outcomeListTextSix:
    'The high-performance and flexibly controllable search across products, downloads, contacts, and website content also optimizes the site.',
  conclusionSectionTitle: 'Conclusion',
  conclusionTitle: 'Future-Proof Platform for Sustainable Success',
  conclusionText:
    '<p>The successful and ongoing collaboration has evolved in a dynamic industrial landscape, positioning Mitsubishi Electric Factory Automation for continued success. The integrated solution not only addresses the initial challenges but also creates a foundation for continuous innovation and adaptability.</p><p>The joint effort ensures that the platform remains future-proof, enabling Mitsubishi to stay at the forefront of technological developments and strategic successes in the field of industrial automation.</p>',
  conclusionBtnLabel: 'Get a free consultation'
})
