// @flow
import React from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './Scrollindicator.scss'
import ScrollAnimation from 'react-animate-on-scroll'

type Props = {
  light?: boolean,
  absolute?: boolean,
  ...StyleProps
}

const Scrollindicator = ({ light, absolute, styles }: Props) => (
  <ScrollAnimation
    className={classNames(styles.root, light && styles.isLight, absolute && styles.absolute)}
    animateIn="fadeOut"
    animatePreScroll={false}
    animateOnce
  />
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Scrollindicator)
