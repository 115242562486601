// @flow

import React from 'react'
import classNames from 'classnames'
import { Parallax } from 'react-scroll-parallax'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from '../../../components/basic/Button'
import { Container } from '../../../components/basic/Grid'
import Scrollindicator from '../../basic/Scrollindicator'
import BackgroundOverlay from '../../CommonLanding/shared/BackgroundOverlay'

import messages from '../CaseStudyModern.lang'
import baseStyles from './MainTeaser.scss'

type Props = {
  title?: any,
  logo?: any,
  logoBg?: string,
  modeSettings?: Object,
  ...StyleProps
}

const MainTeaser = ({ modeSettings, title, logo, logoBg, styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, modeSettings && modeSettings.mode === 'dark' && styles.isDark)}>
      <BackgroundOverlay styles={styles.overlay} {...modeSettings} />
      <Container className={styles.container} fluid>
        <div className={styles.backLinkWrapper}>
          <Link to="/case-study/">
            <Button styles={styles.backLink} tag="span" color="link" icon="icon-arrow_03-left-medium">
              {formatMessage(messages.backBtnLabel)}
            </Button>
          </Link>
        </div>
        {(title || logo) && (
          <Parallax y={[-10, 10]} className={styles.parallaxWrapper}>
            <div>
              {title && <h1 dangerouslySetInnerHTML={{ __html: formatMessage(title) }} className={styles.title} />}
              {logo && (
                <div className={styles.logoWrapper}>
                  <div className={classNames(styles.logo, logoBg && styles['is-' + logoBg])}>{logo}</div>
                </div>
              )}
            </div>
          </Parallax>
        )}
      </Container>
      <Scrollindicator light absolute />
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(MainTeaser)
