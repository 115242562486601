// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudyModern from '../../../components/CaseStudyModern'
import pageMessages from '../../../messages/pageTitles.lang'
import metaMessages from '../../../messages/pageMetaDescriptions.lang'
import SEO from '../../../components/seo'

import messages from '../../../components/CaseStudyModern/messages/mitsubishi/mitsubishi.lang'
import caseStudyMessages from '../../../components/CaseStudyModern/CaseStudyModern.lang'

/* eslint-disable max-len */
const mainTeaserBg = (
  <StaticImage src="../../../images/casestudy/mitsubishi/mainteaser.png" alt="" objectFit="cover" className="imageCover imageWithOverlay" />
)
const caseLogo = (
  <StaticImage src="../../../images/casestudy/mitsubishi/caseLogo.jpg" alt="" objectFit="contain" className="image" />
)
const caseTheCompany = (
  <StaticImage src="../../../images/casestudy/mitsubishi/main.png" alt="" objectFit="contain" className="image" />
)
const challengeImage = (
  <StaticImage src="../../../images/casestudy/mitsubishi/challengeImage.png" alt="" objectFit="contain" className="image" />
)
const caseChallengesOne = (
  <StaticImage src="../../../images/casestudy/mitsubishi/mockup-backend.png" alt="" objectFit="contain" className="image" />
)
const caseChallengesTwo = (
  <StaticImage src="../../../images/casestudy/mitsubishi/mockup-backend2.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryOne = (
  <StaticImage src="../../../images/casestudy/mitsubishi/gallary1.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryTwo = (
  <StaticImage src="../../../images/casestudy/mitsubishi/gallary2.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryThree = (
  <StaticImage src="../../../images/casestudy/mitsubishi/gallary3.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryFour = (
  <StaticImage src="../../../images/casestudy/mitsubishi/gallary4.png" alt="" objectFit="contain" className="image" />
)
const casePerformance = (
  <StaticImage src="../../../images/casestudy/mitsubishi/performance.png" alt="" objectFit="contain" className="image" />
)
const quoteHarald = (
  <StaticImage src="../../../images/casestudy/mitsubishi/harald.png" alt="" objectFit="cover" className="imageCover" />
)
const caseTheImplementation = (
  <StaticImage src="../../../images/casestudy/mitsubishi/implementation.png" alt="" objectFit="contain" className="image" />
)
const caseTheOutcomeOne = (
  <StaticImage src="../../../images/casestudy/mitsubishi/outcome1.png" alt="" objectFit="contain" className="image" />
)
const caseTheOutcomeTwo = (
  <StaticImage src="../../../images/casestudy/mitsubishi/outcome2.png" alt="" objectFit="contain" className="image" />
)
const caseConclusion = (
  <StaticImage src="../../../images/casestudy/mitsubishi/conclusion.png" alt="" objectFit="contain" className="image" />
)
const contactImage = (
  <StaticImage src="../../../images/casestudy/smm/Rebecca.png" alt="" objectFit="cover" className="imageCover" />
)

type Props = {
  ...StyleProps
}

const data = [
  {
    type: 'MainTeaser',
    title: messages.mainTeaserTitle,
    logo: caseLogo,
    logoBg: 'white',
    modeSettings: {
      mode: 'dark',
      bgImg: mainTeaserBg,
      linearGradient: 'orange'
    }
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light'
    },
    sectionTitle: caseStudyMessages.theCompany,
    sectionIcon: 'icon-company',
    sectionIconColour: 'accent',
    title: messages.theCompanySectionTitle,
    text: messages.theCompanySectionText,
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseTheCompany,
        clickable: false
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    sectionIcon: 'icon-challenge',
    sectionIconColour: 'tertiary',
    sectionTitle: caseStudyMessages.theChallenge,
    title: messages.theChallengeTitle,
    text: messages.theChallengeText,
    galleryAlignment: 'left',
    gallery: [
      {
        img: challengeImage,
        clickable: false
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light',
      noPaddingBottom: true
    },
    title: messages.technicalChallengeTitle,
    list: [
      {
        title: messages.multilingualContentTitle,
        text: messages.multilingualContentText
      },
      {
        title: messages.customUserRolesTitle,
        text: messages.customUserRolesText
      },
      {
        title: messages.seamlessIntegrationTitle,
        text: messages.seamlessIntegrationText
      }
    ],
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseChallengesOne,
        clickable: false
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light',
      noPaddingTop: true
    },
    list: [
      {
        title: messages.localizationTitle,
        text: messages.localizationText
      },
      {
        title: messages.contentGovernanceTitle,
        text: messages.contentGovernanceText
      },
      {
        title: messages.contentSearchTitle,
        text: messages.contentSearchText
      }
    ],
    galleryTitle: messages.smmSectionGalleryTitle,
    galleryAlignment: 'left',
    gallery: [
      {
        img: caseChallengesTwo,
        clickable: false
      }
    ],
    imageRow: {
      items: [
        {
          img: caseGallaryOne
        },
        {
          img: caseGallaryTwo
        },
        {
          img: caseGallaryThree
        },
        {
          img: caseGallaryFour
        }
      ]
    }
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    title: messages.performanceSectionTitle,
    list: [
      {
        title: messages.performanceOptiomizationTitle,
        text: messages.performanceOptiomizationText
      },
      {
        title: messages.securityTitle,
        text: messages.securityText
      },
      {
        title: messages.scalabilityTitle,
        text: messages.scalabilityText
      },
      {
        title: messages.userTrainingAndAdoptionTitle,
        text: messages.userTrainingAndAdoptionText
      },
      {
        title: messages.interoperabilityTitle,
        text: messages.interoperabilityText
      }
    ],
    galleryAlignment: 'right',
    gallery: [
      {
        img: casePerformance,
        clickable: false
      }
    ],
    quotes: [
      {
        img: quoteHarald,
        quote: messages.sectionQuoteText1,
        name: 'Harald Borkott',
        position: 'Project Manager',
        company: 'freshcells systems engineering GmbH'
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light'
    },
    sectionIcon: 'icon-icon-process',
    sectionIconColour: 'secondary',
    sectionTitle: messages.implementationSectionTitle,
    title: messages.implementationTitle,
    text: messages.implementationText,
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseTheImplementation,
        clickable: false
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black',
      noPaddingBottom: true
    },
    sectionIcon: 'icon-done-all',
    sectionIconColour: 'success',
    sectionTitle: messages.outcomeSectionTitle,
    title: messages.outcomeTitle,
    text: messages.outcomeText,
    galleryAlignment: 'left',
    gallery: [
      {
        img: caseTheOutcomeOne,
        clickable: false
      }
    ],
    list: [
      {
        text: messages.outcomeListTextOne
      },
      {
        text: messages.outcomeListTextTwo
      },
      {
        text: messages.outcomeListTextThree
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black',
      noPaddingTop: true
    },
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseTheOutcomeTwo,
        clickable: false
      }
    ],
    list: [
      {
        text: messages.outcomeListTextFour
      },
      {
        text: messages.outcomeListTextFive
      },
      {
        text: messages.outcomeListTextSix
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light',
    },
    sectionIcon: 'icon-conclusion',
    sectionIconColour: 'success',
    sectionTitle: messages.conclusionSectionTitle,
    title: messages.conclusionTitle,
    text: messages.conclusionText,
    galleryAlignment: 'left',
    buttonLabel: messages.conclusionBtnLabel,
    buttonLink: '/contact/',
    gallery: [
      {
        img: caseConclusion,
        clickable: false
      }
    ],
  },
  {
    type: 'RelatedCS',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    relatedCases: ['smm', 'plattenplaner', 'lhh', 'dertouristik', 'weekend', 'aldiana', 'bedfinder', 'etravel', 'ytravel']
  },
  {
    type: 'FloatingCTA',
    img: contactImage
  }
]

const CaseMitsubishi = ({ styles }: Props) => {
  const { locale, formatMessage } = useIntl()
  return (
    <Layout footer={<Footer modern />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(pageMessages.TitleCaseStudyMitsubishi)}
        description={formatMessage(metaMessages.CaseStudyMitsubishiDescription)}
        lang={locale}
        nofollow
      />
      <CaseStudyModern data={data} />
    </Layout>
  )
}

export default applyStylingHoc({})<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseMitsubishi)
